import { render, staticRenderFns } from "./CMCourseConfig.vue?vue&type=template&id=58b319eb&scoped=true&lang=pug&"
import script from "./CMCourseConfig.vue?vue&type=script&lang=js&"
export * from "./CMCourseConfig.vue?vue&type=script&lang=js&"
import style0 from "./CMCourseConfig.vue?vue&type=style&index=0&id=58b319eb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58b319eb",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VDialog,VIcon,VSkeletonLoader,VSpacer,VTextarea})
